import React, { useContext } from 'react'
import { VIEW } from '../util/Constants'
import { GlobalContext } from '../context/GlobalContextProvider';

export default function Navbar() {
  const { isLoggedIn, currentView, onNavClick } = useContext(GlobalContext);

  return (
    <div className="Navbar bg-[#24386c] h-12 text-[#e8ddfe] flex flex-row justify-between items-center font-roboto flex-shrink-0">
        <div className="w-1/4 font-bold">
          <button onClick={() => onNavClick(VIEW.ALL_JOBS)} className='bg-[#dc244c] py-2 px-4 rounded-sm'>Hiring42</button>
          {/* <Link to={ROUTE_PATH.ALL_JOBS}>Hiring42</Link> */}
        </div>
        <div className="flex flex-row justify-between w-1/2 text-sm">
          <button onClick={() => onNavClick(VIEW.ALL_JOBS)} className={currentView === VIEW.ALL_JOBS ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>All Jobs</button>
          <button onClick={() => onNavClick(VIEW.ALL_CANDIDATES)} className={currentView === VIEW.ALL_CANDIDATES ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>All Candidates</button>
          <button onClick={() => onNavClick(VIEW.MY_JOBS)} className={currentView === VIEW.MY_JOBS ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>My Jobs</button>
          <button onClick={() => onNavClick(VIEW.MY_CANDIDATES)} className={currentView === VIEW.MY_CANDIDATES ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>My Candidates</button>
          {/* <button onClick={() => onNavClick(VIEW.MESSAGING)} className={currentView === VIEW.MESSAGING ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>Messaging</button> */}
          <button onClick={() => onNavClick(VIEW.CONTACT)} className={currentView === VIEW.CONTACT ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>Contact</button>
        </div>
        <div className="w-1/4 flex justify-end pr-3 text-sm">
          {isLoggedIn ? (
            <button onClick={() => onNavClick(VIEW.LOGOUT)} className={currentView === VIEW.LOGOUT ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>Logout</button>
          ) : (
            <button onClick={() => onNavClick(VIEW.LOGIN)} className={currentView === VIEW.LOGIN ? 'bg-[#dc244c] py-2 px-4 rounded-sm' : 'bg-[#24386c] py-2 px-4'}>Login</button>
          )}
        </div>
    </div>
  )
}
