import React, { useContext, useMemo } from 'react'
import { useUtilManager } from '../util/UtilManager';
import { GlobalContext } from '../context/GlobalContextProvider';
import { POP_UP } from '../util/Constants';

export default function JobList(props) {
    const { isLoading, setPopup, setRecommendation } = useContext(GlobalContext);
    const { formatTs } = useUtilManager();

    const emptyMessage = useMemo(() => {
        if(props.jobList && props.jobList.length > 0) {
            return "Jobs loaded";
        }
        if (isLoading) {
            return "Loading jobs...";
        }
        return props.emptyListMessage || "No jobs found!!!";
    }, [isLoading, props.emptyListMessage, props.jobList]);

    const colSpanForEmptyMessage = useMemo(() => {
        if (props.displayScore && props.displayRecommendation) {
            return 7;
        }
        if (props.displayScore && !props.displayRecommendation) {
            return 6;
        }
        return 5;
    }, [props.displayScore, props.displayRecommendation]);

    const getRecommendation = (job) => {
        if (job.recommendation === undefined) {
            return "On Demand";
        }
        return job.recommendation;
    }

    const handleRecommendationClick = (event, job) => {
        event.stopPropagation();
        let recommendation = {};
        recommendation.rec = job.recommendation;
        recommendation.reasoning = job.reasoning;
        setRecommendation(recommendation);
        setPopup(POP_UP.AI_RECOMMENDATION_POPUP);
    };

    return (
        <table
            className="font-roboto text-[11px] text-left break-words overflow-hidden"
            cellPadding="1"
            cellSpacing="0"
            style={{ tableLayout: "fixed", width: props.tableWidth }}
        >
            <thead className="">
                <tr className="bg-[#dc244c] text-[#e8ddfe]">
                    <th className="w-[45px] font-semibold rounded-l-sm pl-1">Select</th>
                    <th className="w-[450px] font-semibold pl-1">Title</th>
                    <th className="w-[160px] font-semibold pl-1">Location</th>
                    <th className="w-[200px] font-semibold pl-1">Posted By</th>
                    {props.displayScore && props.displayRecommendation && (
                        <>
                            <th className="w-[130px] font-semibold pl-1">Last Updated On</th>
                            <th className='w-[75px] font-semibold pl-1'>Score(%)</th>
                            <th className='w-[110px] font-semibold text-left pr-[2px] rounded-r-sm'>AI Recommendation</th>
                        </>
                    )}
                    {props.displayScore && !props.displayRecommendation && (
                        <>
                            <th className="w-[130px] font-semibold pl-1">Last Updated On</th>
                            <th className='w-[75px] font-semibold text-right pr-[6px] rounded-r-sm'>Score(%)</th>
                        </>
                    )}
                    {!props.displayScore && !props.displayRecommendation && (
                        <th className="w-[130px] font-semibold pr-[6px] text-right rounded-r-sm">Last Updated On</th>
                    )}
                </tr>
            </thead>
            <tbody>
                {!props.jobList || props.jobList.length === 0 ? (
                    <tr>
                        <td colSpan={colSpanForEmptyMessage} className="font-roboto text-center text-gray-800 py-2 text-sm">
                            {emptyMessage}
                        </td>
                    </tr>
                ) : (
                    props.jobList.map((job, index) => {
                        const rowNumber = index % 2 === 0;
                        let bgColor = rowNumber ? "bg-white" : "bg-gray-100";
                        if (props.jobList.length === 1) {
                            bgColor = "bg-gray-200";
                        }
                        const hoverBgColor = "hover:bg-[#dc244c]";
                        return (
                            <tr
                                key={`${job.id}-${job.recommendation}`}
                                className={`cursor-pointer ${bgColor} ${hoverBgColor} hover:bg-opacity-20 text-gray-700 hover:text-[#7532f9]`}
                                onClick={(event) => {
                                    if (event.target.classList.contains('select-column') || event.target.classList.contains('select-checkbox')) {
                                        return;
                                    }
                                    props.handleJobClick(job);
                                }}
                            >
                                <td className="select-column pl-3 pt-1">
                                    <input type="checkbox"
                                        checked={props.isJobSelected(job)}
                                        className="select-checkbox"
                                        onChange={() => props.handleCheckBoxChange(job)} />
                                </td>
                                <td className="text-[#7532f9] font-semibold truncate pl-1 align-top">{job.title}</td>
                                <td className="pl-1 pr-1 truncate align-top">{job.loc}</td>
                                <td className="pl-1 pr-1 truncate align-top">{job.uid}</td>
                                {props.displayScore && props.displayRecommendation && (
                                    <>
                                        <td className="pl-1 pr-1 truncate align-top">{formatTs(job.ts)}</td>
                                        <td className="pl-1 pr-1 truncate align-top font-semibold">{job.score}</td>
                                        <td
                                            className={`pl-1 pr-[6px] truncate align-top text-center cursor-pointer ${job.recommendation !== undefined ? "font-semibold text-[#7532f9] hover:text-[#dc244c]" : ""}`}
                                            onClick={(event) => handleRecommendationClick(event, job)}
                                        >
                                            {getRecommendation(job)}
                                        </td>
                                    </>
                                )}
                                {props.displayScore && !props.displayRecommendation && (
                                    <>
                                        <td className="pl-1 pr-1 truncate align-top">{formatTs(job.ts)}</td>
                                        <td className="pl-1 pr-[6px] truncate align-top text-right font-semibold">{job.score}</td>
                                    </>
                                )}
                                {!props.displayScore && !props.displayRecommendation && (
                                    <td className="pl-1 pr-[6px] truncate align-top text-right">{formatTs(job.ts)}</td>
                                )}
                            </tr>
                        );
                    })
                )}
            </tbody>
        </table>
    );
}