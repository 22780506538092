export default function ContactView() {
    return (
        <div className='font-roboto flex flex-col h-full mt-28'>
            <p className='text-gray-800 text-base'>
                    <span>
                        Please send an email to <span className="text-blue-800 font-semibold">'contact@hiring42.com'</span> for any query, issue, complaint or feedback.
                    </span>
            </p>
        </div>

    )
}
