import React from 'react'
import { useNavigate } from 'react-router-dom';
import { VIEW } from '../../util/Constants';

export default function LoginPrompt() {
    const navigate = useNavigate();

    const handleLoginClick = (event) => {
        event.stopPropagation();
        navigate(`/${VIEW.LOGIN}`);
    }

  return (
    <div className="flex flex-col items-center justify-start h-full mt-32 font-roboto w-full">
            <p className="text-xl font-bold text-gray-800">Please login to proceed...</p>
            <button onClick={(event) => handleLoginClick(event)} className="font-semibold px-10 py-2 mt-4 rounded-[3px] bg-[#24386c] hover:bg-[#dc244c] text-[#e8ddfe] border-[#24386c] border">Login</button>
        </div>
  )
}
