import React, { useContext, useEffect, useState } from 'react'
import { VIEW } from '../../util/Constants';
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from '../../context/GlobalContextProvider';
/* eslint-disable no-unused-vars */
import log from '../../util/Logger';
import { toast } from 'react-toastify';

const genOtpUrl = "https://q1xp2yal7d.execute-api.ap-south-1.amazonaws.com/Prod/generate-otp"
const signupUrl = "https://q1xp2yal7d.execute-api.ap-south-1.amazonaws.com/Prod/signup"
export default function Signup() {
    const { setIsLoading, setSid, setIsLoggedIn, currentView, setUid } = useContext(GlobalContext);
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailSubmitted, setIsEmailSubmitted] = useState(false);
    const [isOtpPasswordSubmitted, setIsOtpPasswordSubmitted] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [accountExists, setAccountExists] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handleOtpChange = (e) => setOtp(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleGetOtp = async (e) => {
        e.preventDefault();
        setIsEmailSubmitted(true);
        if (email) {
            setIsLoading(true);
            const payload = {
                email: email,
                context: currentView
            };
            const postParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            };
            try {
                const response = await fetch(genOtpUrl, postParams);
                if (!response.ok) {
                    const errorResponse = await response.json();
                    if (errorResponse.message === 'account_already_exists') {
                        setAccountExists(true);
                    } else {
                        throw new Error(errorResponse.message);
                    }
                } else {
                    setIsOtpSent(true);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleSignup = async (e) => {
        e.preventDefault();
        setIsOtpPasswordSubmitted(true);
        if (otp && password) {
            setIsLoading(true);
            const payload = {
                email: email,
                otp: otp,
                password: password
            };
            const postParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            };
            try {
                const response = await fetch(signupUrl, postParams);
                if (!response.ok) {
                    const errorResponse = await response.json();
                    throw new Error(errorResponse.message);
                } else {
                    const responseData = await response.json();
                    const sid = responseData.sid;
                    setSid(sid);
                    setIsLoggedIn(true);
                    setUid(email);
                    localStorage.setItem('sid', sid);
                    navigate(`/${VIEW.ALL_JOBS}`);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleForgotPassword = () => {
        navigate(`/${VIEW.FORGOT_PASSWORD}`);
    };

    const handleLoginClick = () => {
        navigate(`/${VIEW.LOGIN}`);
    }

    useEffect(() => {
        setEmail('');
        setOtp('');
        setPassword('');
        setIsEmailSubmitted(false);
        setIsOtpPasswordSubmitted(false);
        setIsOtpSent(false);
        setAccountExists(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView]);

    return (
        <div className="flex flex-col items-center justify-start h-full mt-32 font-roboto w-full">
            {isOtpSent ?
                (
                    <>
                        {
                        currentView === VIEW.SIGNUP ?
                            <p className="text-2xl font-bold text-gray-800">Verify your email</p>
                            : <p className="text-2xl font-bold text-gray-800">Reset your password</p>
                        }
                        <p className="text-gray-800 mt-2">We have sent an otp to{' '} <span className='cursor-pointer text-blue-600'>{email}</span>.</p>
                        <p className="text-gray-800">Please enter the otp and password to proceed.</p>
                        <form className="flex flex-col items-center justify-center w-full mt-5" onSubmit={handleSignup}>
                            <input type="text" placeholder="Otp" id="otp" onChange={handleOtpChange} value={otp} className={`font-lato w-1/5 p-3 border-[1px] ${isOtpPasswordSubmitted && !otp ? 'border-red-500' : 'border-gray-400'} focus:border-[#24386c] focus:border-[2px] rounded-md focus:outline-none`} />
                            <input type="password" placeholder="Password" id="password" onChange={handlePasswordChange} value={password} className={`font-lato w-1/5 p-3 m-2 border-[1px] ${isOtpPasswordSubmitted && !password ? 'border-red-500' : 'border-gray-400'} focus:border-[#24386c] focus:border-[2px] rounded-md focus:outline-none`} />
                            <button type="submit" className="font-semibold px-4 py-2 mt-5 rounded-[3px] bg-[#24386c] hover:bg-[#dc244c] text-[#e8ddfe] border-[#24386c] border">Submit</button>
                        </form>
                    </>
                ) :
                (
                    <>
                        {
                        currentView === VIEW.SIGNUP ?
                            <p className="text-2xl font-bold text-gray-800">Create your account</p>
                            : <p className="text-2xl font-bold text-gray-800">Reset your password</p>
                        }
                        <form className="flex flex-col items-center justify-center w-full mt-5" onSubmit={handleGetOtp}>
                            <input type="text" placeholder="Email" id="email" onChange={handleEmailChange} value={email} className={`font-lato w-1/5 p-3 border-[1px] ${isEmailSubmitted && !email ? 'border-red-500' : 'border-gray-400'} focus:border-[#24386c] focus:border-[2px] rounded-md focus:outline-none`} />
                            {accountExists &&

                                <p className=" text-red-600 mt-3 text-[13.5px]">Account already exist.{' '}
                                    <span className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={handleForgotPassword}>
                                        Forgot password?
                                    </span>
                                </p>
                            }
                            <button type="submit" className="font-semibold px-4 py-2 mt-5 rounded-[3px] bg-[#24386c] hover:bg-[#dc244c] text-[#e8ddfe] border-[#24386c] border">Get Otp</button>
                        </form>
                    </>
                )
            }
            <p className=" text-gray-800 mt-3">Already have an account?{' '}
                <span className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={handleLoginClick}>
                    Login
                </span>
            </p>
        </div>
    )
}
