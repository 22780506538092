import { useNavigate } from "react-router-dom";
import { VIEW } from "./Constants";
import { JobContext } from "../context/JobContextProvider";
import { CandidateContext } from "../context/CandidateContextProvider";
import { useContext, useMemo } from "react";
import { GlobalContext } from "../context/GlobalContextProvider";
import { toast } from "react-toastify";
/* eslint-disable no-unused-vars */
import log from "./Logger";

const getRecommendationApi =
  "https://p757tbfjc9.execute-api.ap-south-1.amazonaws.com/Prod/get-recommendations";
const updateCometUserContactDetailsApi =
  "https://2662651c692d6cb5.api-in.cometchat.io/v3/notifications/v1/contact-details";
const frontendMetricPublisherApi =
  "https://umsujy301a.execute-api.ap-south-1.amazonaws.com/Prod/publish-metric";
export const useUtilManager = () => {
  const navigate = useNavigate();
  const { isLoggedIn, uid, setIsLoading, currentView } =
    useContext(GlobalContext);
  const {
    isAnyJobSelected,
    selectedJobs,
    handleFindSimilarJobs,
    handleFindJobs,
  } = useContext(JobContext);

  const {
    isAnyCandidateSelected,
    selectedCandidates,
    handleFindSimilarCandidates,
    handleFindCandidates,
  } = useContext(CandidateContext);

  const isQA = useMemo(() => {
    return isLoggedIn && uid === "ankit@jobsreq.com";
  }, [isLoggedIn, uid]);

  const capitalizeFirstChar = (str) => {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formatTs = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const timeString = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    const dateString = date.toLocaleDateString("en-US", {
      day: "2-digit",
      month: "short",
      year: "2-digit",
    });
    return `${dateString}, ${timeString.toLowerCase()}`;
  };

  const getAiRecommendation = async (jobs, candidates, src_type, dst_type) => {
    setIsLoading(true);
    let items = [];
    for (let i = 0; i < jobs.length; i++) {
      for (let j = 0; j < candidates.length; j++) {
        items.push({
          job_id: jobs[i].id,
          cand_id: candidates[j].id,
          src_type: src_type,
          dst_type: dst_type,
        });
      }
    }
    const payload = {
      items: items,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(getRecommendationApi, postParams);
      if (response.ok) {
        const recommendations = await response.json();
        return recommendations;
      } else {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      log.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const publishMetric = async (name, value, unit) => {
    const payload = {
      name: name,
      value: value,
      unit: unit,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    fetch(frontendMetricPublisherApi, postParams).catch((error) => {
      console.error("Error publishing metric:", error.message);
    });
  };

  const handleSubmitToCandidatesClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to submit to candidates!");
      return;
    }
    publishMetric(`find_candidates_${currentView}`, 1, "Count");
    navigate(`/${VIEW.SUBMIT_TO_CANDIDATES}`);
  };

  const handleSubmitYourCandidatesClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to submit your candidates!");
      return;
    }
    if (!isLoggedIn) {
      toast.warn("Please login to submit candidates!");
      navigate(`/${VIEW.LOGIN}`);
      return;
    }
    navigate(`/${VIEW.SUBMIT_YOUR_CANDIDATES}`);
  };

  const handleFindSimilarJobsClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to find similar jobs!");
      return;
    }
    navigate(`/${VIEW.JOB_SEARCH_RESULTS}/${selectedJobs[0].id}`);
    handleFindSimilarJobs(selectedJobs[0]);
  };

  const handleFindCandidatesClick = () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to find candidates!");
      return;
    }
    navigate(`/${VIEW.CANDIDATE_SEARCH_RESULTS}/${selectedJobs[0].id}`);
    handleFindCandidates(selectedJobs[0]);
  };

  const handleSubmitToJobsClick = async () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to submit to jobs!");
      return;
    }
    navigate(`/${VIEW.SUBMIT_TO_JOBS}`);
  };

  const handleSubmitYourJobsClick = async () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to submit your jobs!");
      return;
    }
    if (!isLoggedIn) {
      toast.warn("Please login to submit candidates!");
      navigate(`/${VIEW.LOGIN}`);
      return;
    }
    navigate(`/${VIEW.SUBMIT_YOUR_JOBS}`);
  };

  const handleSimilarCandidatesClick = () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to find similar candidates!");
      return;
    }
    handleFindSimilarCandidates(selectedCandidates[0]);
  };

  const handleFindJobsClick = () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to find jobs!");
      return;
    }
    navigate(`/${VIEW.JOB_SEARCH_RESULTS}/${selectedCandidates[0].id}`);
    handleFindJobs(selectedCandidates[0]);
  };

  const handleSubmissionToJobClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to see submissions!");
      return;
    }
    navigate(`/${VIEW.SUBMISSIONS_TO_JOB}/${selectedJobs[0].id}`);
  };

  const handleSubmissionOfJobClick = async () => {
    if (!isAnyJobSelected) {
      toast.warn("Please select a job to see submissions!");
      return;
    }
    navigate(`/${VIEW.SUBMISSIONS_OF_JOB}`);
  };

  const handleSubmissionToCandidateClick = async () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to see submissions!");
      return;
    }
    navigate(`/${VIEW.SUBMISSIONS_TO_CANDIDATE}`);
  };

  const handleSubmissionOfCandidateClick = async () => {
    if (!isAnyCandidateSelected) {
      toast.warn("Please select a candidate to see submissions!");
      return;
    }
    navigate(`/${VIEW.SUBMISSIONS_OF_CANDIDATE}`);
  };

  const getNameFromUid = (uid) => {
    return (
      uid.split("@")[0].charAt(0).toUpperCase() + uid.split("@")[0].slice(1)
    );
  };

  const getChatUidFromUid = (uid) => {
    return uid.replace(/[@.]/g, "_");
  };

  const updateCometUserContact = async (chatUid, uid) => {
    const options = {
      method: "PATCH",
      headers: {
        accept: "application/json",
        "content-type": "application/json",
        onBehalfOf: chatUid,
        apikey: "b4b5c992ef5a30c7981944b58a287f6d16fc8f84",
      },
      body: JSON.stringify({ email: uid }),
    };

    fetch(updateCometUserContactDetailsApi, options)
      .then((res) => res.json())
      .catch((err) => console.error(err));
  };

  const sendMessageToTargetUid = async (targetUid) => {
    if (currentView !== VIEW.SUBMISSIONS_TO_JOB) {
      publishMetric(`send_message_${currentView}_${isLoggedIn}`, 1, "Count");
    }
    const chatUid = getChatUidFromUid(targetUid);
    const user = new window.CometChatWidget.CometChat.User(chatUid);
    user.setName(getNameFromUid(targetUid));
    window.CometChatWidget.createOrUpdateUser(user).then(
      () => {
        updateCometUserContact(chatUid, targetUid);
        window.CometChatWidget.chatWithUser(chatUid);
        window.CometChatWidget.openOrCloseChat(true);
      },
      (error) => {
        console.log("New target user create or update failed:", error);
      }
    );
  };

  const doNothing = () => {};

  return {
    isQA,
    capitalizeFirstChar,
    formatTs,
    doNothing,
    handleSubmitToCandidatesClick,
    handleSubmitYourCandidatesClick,
    handleFindSimilarJobsClick,
    handleFindCandidatesClick,
    handleSubmitToJobsClick,
    handleSubmitYourJobsClick,
    handleSimilarCandidatesClick,
    handleFindJobsClick,
    handleSubmissionToJobClick,
    handleSubmissionOfJobClick,
    handleSubmissionToCandidateClick,
    handleSubmissionOfCandidateClick,
    getAiRecommendation,
    getNameFromUid,
    getChatUidFromUid,
    updateCometUserContact,
    sendMessageToTargetUid,
    publishMetric,
  };
};
