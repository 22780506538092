import React, { useEffect, useRef } from 'react';

export default function AdobePdfViewer({ resume_file_data, resume_file_name }) {
    const adobeDCViewRef = useRef(null);

    useEffect(() => {
        const onSDKReady = () => {
            if(resume_file_data && !adobeDCViewRef.current) {
                adobeDCViewRef.current = new window.AdobeDC.View({
                    clientId: "36b85a2b469b46548103af013585a6ad",
                    divId: "adobe-dc-view"
                });
        
                adobeDCViewRef.current.previewFile({
                    content: { location: { url: resume_file_data }},
                    metaData: { fileName: resume_file_name }
                }, {
                    embedMode: "SIZED_CONTAINER",
                    defaultViewMode: "FIT_PAGE"
                });
            }
        };
    
        if (window.AdobeDC) {
            onSDKReady();
        } else {
            document.addEventListener("adobe_dc_view_sdk.ready", onSDKReady);
        }
    
        return () => {
            document.removeEventListener("adobe_dc_view_sdk.ready", onSDKReady);
        };
    }, [resume_file_data, resume_file_name]);

    return (
        <div id="adobe-dc-view" className='h-[700px]'>
            {/* Adobe PDF Viewer will be embedded here */}
        </div>
    );
}
