import React, { useContext, useEffect, useMemo } from 'react'
import JobList from '../../module/JobList';
import SearchBox from '../../module/SearchBox';
import { JobContext } from '../../context/JobContextProvider';
import GenericButton from '../../module/GenericButton';
import { GlobalContext } from '../../context/GlobalContextProvider';
import { useUtilManager } from '../../util/UtilManager';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
/* eslint-disable no-unused-vars */
import log from '../../util/Logger';
import { POP_UP, VIEW } from '../../util/Constants';
import { Bounce, toast } from 'react-toastify';
/* eslint-enable no-unused-vars */

export default function JobsView() {
    const { isLoggedIn, currentView, jobViewRefresher, setJobViewRefresher, setPopup } = useContext(GlobalContext);
    const location = useLocation();
    const currentPath = location.pathname;
    let { id } = useParams();
    const navigate = useNavigate();
    const {
        isQA,
        handleSubmitToCandidatesClick,
        handleSubmitYourCandidatesClick,
        handleFindSimilarJobsClick,
        handleFindCandidatesClick,
        handleSubmissionToJobClick,
        sendMessageToTargetUid
    } = useUtilManager();

    const {
        handleAddJobClick,
        status,
        setStatus,
        selectedJobs,
        updateJob,
        jobList,
        lastEvaluatedKeyJobs,
        isAnyJobSelected,
        isJobSelected,
        handleCheckBoxChangeOnJobList,
        handleJobClick,
        fetchJobs,
        setMultipleSelectionAllowedForJobs
    } = useContext(JobContext);

    const isActive = useMemo(() => {
        return status === 'active';
    }, [status]);

    const enablePrev = useMemo(() => {
        return id !== null && id !== undefined;
    }, [id]);

    const isJobSearchView = useMemo(() => {
        return currentView === VIEW.JOB_SEARCH_RESULTS;
    }, [currentView]);

    const isMyJobsView = useMemo(() => {
        return currentView === VIEW.MY_JOBS;
    }, [currentView]);

    const isAllJobsView = useMemo(() => {
        return currentView === VIEW.ALL_JOBS;
    }, [currentView]);

    const handleUpdateJob = () => {
        if (selectedJobs.length === 0) {
            toast.warn('Please select a job to update!');
            return;
        }
        setPopup(POP_UP.UPDATE_JOB_POPUP);
    }

    const handleRenewDate = async () => {
        if (selectedJobs.length === 0) {
            toast.warn("Please select a job to refresh!");
            return;
        }
        const updatedJob = {
            id: selectedJobs[0].id
        };
        await updateJob(updatedJob);
        setJobViewRefresher((jobViewRefresher + 1) % 2);
    }

    const handleDeactivateJob = async () => {
        if (selectedJobs.length === 0) {
            toast.warn("Please select a job to deactivate!");
            return;
        }
        const updatedJob = {
            id: selectedJobs[0].id,
            status: 'inactive',
            uid_status: selectedJobs[0].uid + '#inactive',
        };
        await updateJob(updatedJob);
        setJobViewRefresher((jobViewRefresher + 1) % 2);
    }

    const handleActivateJob = async () => {
        if (selectedJobs.length === 0) {
            toast.warn("Please select a job to activate!");
            return;
        }
        const updatedJob = {
            id: selectedJobs[0].id,
            status: 'active',
            uid_status: selectedJobs[0].uid + '#active',
        };
        await updateJob(updatedJob);
        setJobViewRefresher((jobViewRefresher + 1) % 2);
    }

    const handleDeleteJob = async () => {
        if (selectedJobs.length === 0) {
            toast.warn("Please select a job to delete!");
            return;
        }
        const updatedJob = {
            id: selectedJobs[0].id,
            status: 'deleted',
            uid_status: selectedJobs[0].uid + '#deleted'
        };
        await updateJob(updatedJob);
        setJobViewRefresher((jobViewRefresher + 1) % 2);
    }

    const handleStatusCheckBoxChange = (event) => {
        setStatus(event.target.checked ? 'inactive' : 'active');
        navigate(`/${currentView}`);
    };


    const handleRefresh = () => {
        if (enablePrev) {
            navigate(`/${currentView}`);
        } else {
            fetchJobs(null, currentView);
        }
    };

    const handlePrev = () => {
        if (enablePrev) {
            navigate(-1);
        }
    };

    const handleNext = () => {
        navigate(`/${currentView}/${lastEvaluatedKeyJobs}`);
    };

    const sendMessageClick = async () => {
        if (!isAnyJobSelected) {
          toast.warn("Please select a job to send a message!");
          return;
        }
        if (!isLoggedIn) {
          toast.warn("Please login to send message!");
          return;
        }
        sendMessageToTargetUid(selectedJobs[0].uid);
      };

    useEffect(() => {
        const currentView = currentPath.split('/')[1];
        if (currentView === VIEW.ALL_JOBS || currentView === VIEW.MY_JOBS) {
            fetchJobs(id, currentView);
            setMultipleSelectionAllowedForJobs(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [jobViewRefresher, currentPath, status]);

    return (
        <>
            <SearchBox />
            <div>
                <div className='mt-4 text-[#e8ddfe] mb-1 flex flex-row justify-between text-right font-roboto text-[11px] font-semibold'>
                    <div className='flex flex-row justify-start'>
                        {isJobSearchView && (
                            <>
                            <GenericButton enable={isAnyJobSelected} callback={handleSubmitYourCandidatesClick} text={"Submit Your Candidates"} mr={'mr-2'} ml={'ml-0'} />
                            <GenericButton enable={isAnyJobSelected && isLoggedIn} callback={sendMessageClick} text={"Chat with Recruiter"} mr={"mr-4"} ml={"ml-0"}/>
              </>
                        )}
                        {isMyJobsView && (
                            <>
                                <GenericButton enable={isLoggedIn && !isAnyJobSelected} callback={handleAddJobClick} text={"Add New Job"} mr={'mr-8'} ml={'ml-0'} />
                                <GenericButton enable={isAnyJobSelected} callback={handleSubmitToCandidatesClick} text={"Find Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyJobSelected} callback={handleSubmissionToJobClick} text={"Submissions to this Job"} mr={'mr-8'} ml={'ml-0'} />
                                {/* <GenericButton enable={isAnyJobSelected} callback={handleSubmissionOfJobClick} text={"Shortlisted Candidates"} mr={'mr-8'} ml={'ml-0'} /> */}
                                <GenericButton enable={isAnyJobSelected} callback={handleRenewDate} text={"Renew Date"} mr={'mr-2'} ml={'ml-0'} />
                                {isActive && <GenericButton enable={isAnyJobSelected} callback={handleDeactivateJob} text={"Deactivate"} mr={'mr-2'} ml={'ml-0'} />}
                                {!isActive && <GenericButton enable={isAnyJobSelected} callback={handleActivateJob} text={"Activate"} mr={'mr-2'} ml={'ml-0'} />}
                                {/* <GenericButton enable={isAnyJobSelected} callback={handleDeleteJob} text={"Delete"} mr={'mr-2'} ml={'ml-0'} /> */}
                                <GenericButton enable={isAnyJobSelected} callback={handleUpdateJob} text={"Update"} mr={'mr-8'} ml={'ml-0'} />
                            </>
                        )}
                        {isAllJobsView && (
                            <>
                                <GenericButton enable={isLoggedIn && !isAnyJobSelected} callback={handleAddJobClick} text={"Add New Job"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyJobSelected && isLoggedIn} callback={sendMessageClick} text={"Chat with Recruiter"} mr={"mr-4"} ml={"ml-0"}/>
                                <GenericButton enable={isAnyJobSelected && isLoggedIn} callback={handleSubmitYourCandidatesClick} text={"Submit Your Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyJobSelected} callback={handleFindSimilarJobsClick} text={"Find Similar Jobs"} mr={'mr-2'} ml={'ml-0'} />
                                <GenericButton enable={isAnyJobSelected} callback={handleFindCandidatesClick} text={"Find Candidates"} mr={'mr-2'} ml={'ml-0'} />
                                {isQA && <GenericButton enable={isAnyJobSelected} callback={handleDeleteJob} text={"Delete"} mr={'mr-2'} ml={'ml-0'} />}
                            </>
                        )}
                    </div>
                    {!isJobSearchView && (<div className="flex flex-row justify-end">
                        {isMyJobsView && (
                            <label className="inline-flex items-center mr-2">
                                <input
                                    type="checkbox"
                                    checked={!isActive}
                                    className="form-checkbox h-4 w-4"
                                    onChange={handleStatusCheckBoxChange}
                                />
                                <span className="ml-2 text-gray-800 text-[12px]">Deactivated Jobs</span>
                            </label>)}
                        <GenericButton enable={true} callback={handleRefresh} text={"Refresh"} mr={'mr-2'} ml={'ml-0'} />
                        <GenericButton enable={enablePrev} callback={handlePrev} text={"Prev"} mr={'mr-2'} ml={'ml-0'} />
                        <GenericButton enable={true} callback={handleNext} text={"Next"} mr={'mr-0'} ml={'ml-0'} />
                    </div>)}
                </div>
                <JobList
                    jobList={jobList}
                    tableWidth={"1180px"}
                    isJobSelected={isJobSelected}
                    handleJobClick={handleJobClick}
                    handleCheckBoxChange={handleCheckBoxChangeOnJobList}
                    emptyListMessage={isMyJobsView && isActive ? "No jobs found. Add jobs to get started!!!"  : "No jobs found!!!"}
                    displayScore = {isJobSearchView}
                    displayRecommendation = {false}
                />
            </div>
        </>
    )
}
