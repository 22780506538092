import { createContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { POP_UP, VIEW } from "../util/Constants";
/* eslint-disable no-unused-vars */
import log from "../util/Logger";
import { toast } from "react-toastify";
/* eslint-enable no-unused-vars */

export const GlobalContext = createContext();
const sessionUrl =
  "https://g8xy2tcnjh.execute-api.ap-south-1.amazonaws.com/Prod/validate-session";

export const GlobalContextProvider = ({ children }) => {
  const [currentView, setCurrentView] = useState(VIEW.ALL_JOBS);
  const [previousView, setPreviousView] = useState(VIEW.ALL_JOBS);
  const [jobViewRefresher, setJobViewRefresher] = useState(0);
  const [jobDetailsViewRefresher, setJobDetailsViewRefresher] = useState(0);
  const [candidateViewRefresher, setCandidateViewRefresher] = useState(0);
  const [candidateDetailsViewRefresher, setCandidateDetailsViewRefresher] =
    useState(0);
  const [recommendation, setRecommendation] = useState(null);
  const [popup, setPopup] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [sid, setSid] = useState(null);
  const [uid, setUid] = useState(null);
  const [userName, setUserName] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingBarDuration, setLoadingBarDuration] = useState(5);
  const aiRecommendationPopupRef = useRef(null);
  const navigate = useNavigate();

  const onNavClick = (view) => {
    if (
      (view === VIEW.ALL_JOBS || view === VIEW.MY_JOBS) &&
      view === currentView
    ) {
      setJobViewRefresher((jobViewRefresher + 1) % 2);
    }
    if (
      (view === VIEW.ALL_CANDIDATES || view === VIEW.MY_CANDIDATES) &&
      view === currentView
    ) {
      setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
    }
    navigate(`/${view}`);
  };

  const setViews = (view) => {
    setPreviousView(currentView);
    setCurrentView(view);
  };

  const validateSession = async (sid) => {
    setIsLoading(true);
    const payload = {
      sid: sid,
    };
    const postParams = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(sessionUrl, postParams);
      if (response.ok) {
        const data = await response.json();
        setUid(data.email);
        if (data.name) {
          setUserName(userName);
        }
        setSid(sid);
        setIsLoggedIn(true);
      } else {
        const errorResponse = await response.json();
        throw new Error(errorResponse.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOutsideAiRecommendationPopupClick = (event) => {
    if (
      aiRecommendationPopupRef.current &&
      !aiRecommendationPopupRef.current.contains(event.target)
    ) {
      setPopup(POP_UP.NONE);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideAiRecommendationPopupClick);
    return () => {
      document.removeEventListener(
        "click",
        handleOutsideAiRecommendationPopupClick
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <GlobalContext.Provider
      value={{
        aiRecommendationPopupRef,
        currentView,
        previousView,
        jobViewRefresher,
        jobDetailsViewRefresher,
        candidateViewRefresher,
        candidateDetailsViewRefresher,
        popup,
        sid,
        uid,
        userName,
        isLoggedIn,
        isLoading,
        loadingBarDuration,
        recommendation,
        setLoadingBarDuration,
        setJobViewRefresher,
        setJobDetailsViewRefresher,
        setCandidateViewRefresher,
        setCandidateDetailsViewRefresher,
        setViews,
        setPopup,
        setSid,
        setUid,
        setIsLoggedIn,
        setIsLoading,
        validateSession,
        onNavClick,
        setRecommendation,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
