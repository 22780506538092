import React, { useEffect, useState } from 'react';

function FloatingLabelInput({ label, width, type, inputId, placeHolder, value: propValue, readOnly, isSubmitted, onChange, enableMissingValueCheck = true}) {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(propValue || '');

    const onFeildChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    useEffect(() => {
        if (propValue !== undefined) {
            setValue(propValue);
        }
        if(onChange) {
            onChange(propValue);
        }
    }, [propValue, onChange]);

    return (
        <div className={`relative font-roboto ${width}`}>
            <input
                id={inputId}
                type={type}
                min="0"
                value={value}
                readOnly={readOnly}
                onChange={onFeildChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(value ? true : false)}
                className={`w-full block text-black border-b ${isSubmitted && !value && !isFocused && enableMissingValueCheck ? 'border-red-400 border-b-2' : 'border-[#24386c]'} p-1 focus:outline-none focus:border-b-2 border-opacity-50`}
                placeholder={isFocused ? placeHolder : ''}
            />
            <label htmlFor={inputId} className={`absolute top-0 left-1 transition-transform transition-duration-300 ${isFocused || value ? 'transform -translate-y-4 -translate-x-1 scale-90 text-blue-500' : (isSubmitted && !value && enableMissingValueCheck ? 'text-red-400 text-xs font-semibold' : 'text-gray-500 text-xs')}`}>
                {label}
            </label>

        </div>
    )
}

export default FloatingLabelInput;
