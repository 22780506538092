import React, { useEffect, useState } from 'react';

function FloatingLabelSelect({ options, width, label, inputId, isSubmitted, onChange, defaultValue, enableMissingValueCheck = true}) {
    const [isFocused, setIsFocused] = useState(false);
    const [selectedValue, setSelectedValue] = useState(defaultValue || '');

    const onFeildChange = (e) => {
        setSelectedValue(e.target.value);
        onChange(e.target.value);
    }

    useEffect(() => {
        setSelectedValue(defaultValue);
        if(onChange) {
            onChange(defaultValue);
        }
    }, [defaultValue, onChange]);

    return (
        <div className={`relative font-roboto ${width}`}>
            <select
                id={inputId}
                value={selectedValue}
                onChange={onFeildChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(selectedValue ? true : false)}
                className={`w-full block text-black border-b ${isSubmitted && !selectedValue && !isFocused && enableMissingValueCheck? 'border-red-500' : 'border-[#24386c]'} p-1 focus:outline-none focus:border-b-2`}
            >
                <option value="" hidden></option> {/* Hidden default option */}
                {options.map(option => (
                    <option key={option.value} value={option.value}>{option.label}</option>
                ))}
            </select>
            <label htmlFor={inputId} className={`absolute top-0 left-1 transition-transform transition-duration-300 ${isFocused || selectedValue ? 'transform -translate-y-4 text-[10px] text-blue-500' : (isSubmitted && !selectedValue && enableMissingValueCheck ? 'text-red-400 text-xs' : 'text-gray-400 text-xs')}`}>
                {label}
            </label>
        </div>
    );
}

export default FloatingLabelSelect;
