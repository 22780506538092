import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { toast } from 'react-toastify';
import { GlobalContext } from '../context/GlobalContextProvider';
import log from '../util/Logger';
import { VIEW } from '../util/Constants';

const key = '217312731a27h83t1c28731287321783'
const unsubscribeApi = 'https://vhtq1h8u5j.execute-api.ap-south-1.amazonaws.com/Prod/unsubscribe'
const subscribeApi = 'https://vhtq1h8u5j.execute-api.ap-south-1.amazonaws.com/Prod/subscribe'
export default function UnsubscribeEmailView() {
    let { id } = useParams();
    const { setIsLoading, setLoadingBarDuration } = useContext(GlobalContext);
    const [uid, setUid] = useState(null);
    const [unsubscribed, setUnsubscribed] = useState(false);
    const navigate = useNavigate();

    const unsubscribeUid = async () => {
        setLoadingBarDuration(20);
        setIsLoading(true);
        const payload = {
            email: uid
        };
        log.debug('Unsubscribing payload:', payload);
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(unsubscribeApi, postParams);
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message);
            } else {
                toast.success("Successfully unsubscribed!");
                setUnsubscribed(!unsubscribed);
            }
        } catch (error) {
            toast.error("Error while unsubscribing...");
        } finally {
            setIsLoading(false);
            setLoadingBarDuration(5);
        }
    };

    const subscribeUid = async () => {
        setLoadingBarDuration(20);
        setIsLoading(true);
        const payload = {
            email: uid
        };
        log.debug('Unsubscribing payload:', payload);
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(subscribeApi, postParams);
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message);
            } else {
                setUnsubscribed(!unsubscribed);
                toast.success("Successfully subscribed back!");
                navigate(`/${VIEW.ALL_JOBS}`);
            }
        } catch (error) {
            toast.error("Error while subscribing...");
        } finally {
            setIsLoading(false);
            setLoadingBarDuration(5);
        }
    };

    const decrypt = (id) => {
        try {
            const base64 = id.replace(/-/g, '+').replace(/_/g, '/');
            const encryptedData = CryptoJS.enc.Base64.parse(base64);
            const iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4), 16);
            const ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4), encryptedData.sigBytes - 16);
            const decrypted = CryptoJS.AES.decrypt(
                { ciphertext: ciphertext },
                CryptoJS.enc.Utf8.parse(key),
                {
                    iv: iv,
                    mode: CryptoJS.mode.CFB,
                    padding: CryptoJS.pad.NoPadding,
                }
            );
            return decrypted.toString(CryptoJS.enc.Utf8);
        } catch (error) {
            console.error("Decryption error:", error);
            return null;
        }
    }

    useEffect(() => {
        const decryptedId = decrypt(id);
        setUid(decryptedId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    return (
        <div className='font-roboto flex flex-col h-full mt-28'>
            <p className='text-gray-800 text-base'>
                {unsubscribed ? (
                    <span>
                        <span className="text-blue-800 font-semibold">{uid}</span> has been successfully unsubscribed and will no longer receive any candidate or job submissions.
                    </span>
                ) : (
                    <span>
                        Are you sure you want to unsubscribe <span className="text-blue-800 font-semibold">{uid}</span> from receiving any future candidate or job submissions?
                    </span>
                )}
            </p>
            <div>
                <button
                    className="bg-[#24386c] hover:bg-[#dc244c] py-1 px-2 rounded-sm text-[#e8ddfe] font-semibold mt-9"
                    onClick={(event) => {
                        event.stopPropagation();
                        unsubscribed ? subscribeUid() : unsubscribeUid();
                    }}
                >
                    {unsubscribed ? 'Subscribe Back' : 'Confirm Unsubscribe'}
                </button>
            </div>
        </div>

    )
}
