import React, { useContext, useMemo, useState } from 'react'
import FloatingLabelInput from '../../module/FloatingLabelInput';
import FloatingLabelSelect from '../../module/FloatingLabelSelect';
import { GlobalContext } from '../../context/GlobalContextProvider';
import { POP_UP, VIEW } from '../../util/Constants';
import { JobContext } from '../../context/JobContextProvider';
/* eslint-disable no-unused-vars */
import log from "../../util/Logger";
/* eslint-enable no-unused-vars */
import FloatingLabelTextArea from '../../module/FloatingLabelTextArea';
import { toast } from 'react-toastify';

const jobIngestApiUrl = `https://4a26jc2jg8.execute-api.ap-south-1.amazonaws.com/Prod/add-job`;
export default function JobForm() {
    const [title, setTitle] = useState('');
    const [location, setLocation] = useState('');
    const [exp, setExp] = useState('');
    const [visas, setVisa] = useState('');
    const [desc, setDesc] = useState('');
    const [contract, setContract] = useState('');
    const [workPreference, setWorkPreference] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const {
        addJobPopupRef,
        fetchJobs,
        selectedJobs,
        updateJob,
    } = useContext(JobContext);
    const {
        currentView,
        setPopup,
        setIsLoading,
        uid,
        popup,
        jobViewRefresher,
        jobDetailsViewRefresher,
        setJobViewRefresher,
        setJobDetailsViewRefresher
    } = useContext(GlobalContext);


    const workPreferenceOptions = [
        { label: 'Remote', value: 'Remote' },
        { label: 'Onsite', value: 'Onsite' },
        { label: 'Remote/Onsite Hybrid', value: 'Remote/Onsite Hybrid' }
    ];

    const updateJobLocal = async (updatedJob) => {
        await updateJob(updatedJob);
        setPopup(POP_UP.NONE);
        switch (currentView) {
            case VIEW.MY_JOBS:
                setJobViewRefresher((jobViewRefresher + 1) % 2);
                break;
            case VIEW.JOB_DETAILS:
                setJobDetailsViewRefresher((jobDetailsViewRefresher + 1) % 2);
                break;
            default:
                break;
        }
    };

    const handleJobFormUpdate = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        log.debug('Updated elements:', {title, location, desc, exp, visas, workPreference, contract});
        if (title && location && desc) {
            const updatedJob = {
                id: selectedJobs[0].id,
                uid: selectedJobs[0].uid,
                title: title,
                loc: location,
                exp: exp,
                visas: visas,
                work_pref: workPreference,
                contract: contract,
                desc: desc,
            };
            updateJobLocal(updatedJob);
        } else {
            toast.error('Please fill all mandatory fields!');
        }
    }

    const handleJobFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (title && location && desc) {
            console.log('Form is valid');
            setIsLoading(true);
            const payload = {
                uid: uid,
                title: title,
                loc: location,
                exp: exp,
                visas: visas,
                work_pref: workPreference,
                contract: contract,
                desc: desc,
            };
            const post_params = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            };
            fetch(jobIngestApiUrl, post_params)
                .then((response) => {
                    console.log('Response received:', response);
                    if (!response.ok) {
                        console.log('Response not ok, processing error...');
                        return response.text().then((errorText) => {
                            console.log("Error fetching jobs:", errorText);
                            throw new Error(errorText);
                        });
                    }
                    console.log(response.text)
                })
                .catch((error) => {
                    toast.error(error.message);
                })
                .finally(() => {
                    setIsLoading(false);
                    setPopup(POP_UP.NONE);
                    fetchJobs(null, currentView);
                });
        } else {
            toast.error('Please fill all mandatory fields!');
        }
    }

    const defaultTitle = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].title !== 'undefined' && selectedJobs[0].title !== 'null') {
            return selectedJobs[0].title;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const defaultLoc = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].loc !== 'undefined' && selectedJobs[0].loc !== 'null') {
            return selectedJobs[0].loc;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const defaultExp = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].exp !== 'undefined' && selectedJobs[0].exp !== 'null') {
            return selectedJobs[0].exp;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const defaultContract = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].contract !== 'undefined' && selectedJobs[0].contract !== 'null') {
            return selectedJobs[0].contract;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const defaultVisa = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].visas !== 'undefined' && selectedJobs[0].visas !== 'null') {
            return selectedJobs[0].visas;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const defaultWorkPref = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].work_pref !== 'undefined' && selectedJobs[0].work_pref !== 'null') {
            return selectedJobs[0].work_pref;
        } else {
            return 'Remote';
        }
    }, [selectedJobs]);

    const defaultDesc = useMemo(() => {
        if (selectedJobs.length === 1 && selectedJobs[0].desc !== 'undefined' && selectedJobs[0].desc !== 'null') {
            return selectedJobs[0].desc;
        } else {
            return '';
        }
    }, [selectedJobs]);

    const isUpdateView = useMemo(() => {
        return popup === POP_UP.UPDATE_JOB_POPUP;
    }, [popup]);

    return (
        <div className="fixed inset-0 flex justify-center items-center bg-[#24386c] bg-opacity-20 font-roboto">
            <div ref={addJobPopupRef} className="absolute bg-white p-4 rounded-md border border-[#24386c] shadow-lg w-[900px] h-[600px]">
                <p className='text-base font-semibold'>Add Job</p>
                <form onSubmit={isUpdateView ? handleJobFormUpdate : handleJobFormSubmit}
                    className='text-gray-700 font-roboto text-xs'
                >
                    <div className="mt-6 flex flex-row justify-between">
                        <FloatingLabelInput label='Job Title' width='w-[50%]' inputId='title' placeHolder="i.e. Sr. Solution Architect" value={defaultTitle} isSubmitted={isSubmitted} onChange={setTitle} />
                        <FloatingLabelInput label='Location' width='w-[25%]' inputId='loc' placeHolder="i.e. Houston, Texas, USA" value={defaultLoc} isSubmitted={isSubmitted} onChange={setLocation} />
                        <FloatingLabelInput type='number' label='Required Experience (years)' width='w-[20%]' inputId='exp' placeHolder="" value={defaultExp} isSubmitted={isSubmitted} onChange={setExp} enableMissingValueCheck={false}/>
                    </div>
                    <div className="mt-16 flex flex-row justify-between">
                        <FloatingLabelInput label='Applicable Visas' width='w-[50%]' inputId='visas' placeHolder="US Citizen, GC, H1B etc." value={defaultVisa} isSubmitted={isSubmitted} onChange={setVisa} enableMissingValueCheck={false}/>
                        <FloatingLabelInput label='Contract' width='w-[25%]' inputId='contract' placeHolder="i.e. C2C, W2, 1099, C2H, Full Time" value={defaultContract} isSubmitted={isSubmitted} onChange={setContract} enableMissingValueCheck={false}/>
                        <FloatingLabelSelect options={workPreferenceOptions} width="w-[20%]" label="Work Preference" inputId="workPref" isSubmitted={isSubmitted} onChange={setWorkPreference} defaultValue={defaultWorkPref} enableMissingValueCheck={false}/>
                    </div>
                    <div className="mt-16 flex flex-row justify-between">
                        <FloatingLabelTextArea label='Job Description' width='w-full' inputId='desc' placeHolder="Enter job description here..." value={defaultDesc} isSubmitted={isSubmitted} onChange={setDesc} />
                    </div>

                    <button
                        type="submit"
                        className="bg-[#24386c] hover:bg-[#dc244c] py-1 px-2 rounded-sm text-[#e8ddfe] mt-8 font-semibold">
                        {isUpdateView ? 'Update' : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    )
}
