import { useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { GlobalContext } from '../context/GlobalContextProvider';
/* eslint-disable no-unused-vars */
import log from "./Logger";
import { VIEW } from './Constants';
/* eslint-enable no-unused-vars */

export default function RouteHandler(props) {
    const { setViews } = useContext(GlobalContext);
    let { id, routeView } = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
        if (routeView === undefined) {
            navigate(`/${VIEW.ALL_JOBS}`);
        }
        setViews(routeView);
        // console.log("RouteHandler mounted")
        // return () => {
        //     console.log("RouteHandler unmounted")
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeView, id]);
  return props.renderView()
}
