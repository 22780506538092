import React, { useState, useEffect } from 'react';

function FloatingLabelTextArea({ label, inputId, width, placeHolder, value: propValue, readOnly = false, isSubmitted, onChange, enableMissingValueCheck = true}) {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(propValue || '');

    const onFeildChange = (e) => {
        setValue(e.target.value);
        onChange(e.target.value);
    }

    useEffect(() => {
        setValue(propValue);
        if(onChange) {
            onChange(propValue);
        }
    }, [propValue, onChange]);

    return (
        <div className={`relative font-roboto ${width}`}>
            <textarea
                id={inputId}
                value={value}
                onChange={onFeildChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(value ? true : false)}
                className={`w-full bg-white rounded-sm p-3 overflow-auto focus:outline-none focus:ring-0 text-gray-800 resize-none border border-opacity-50 focus:border-2 ${isSubmitted && !value && !isFocused && enableMissingValueCheck ? 'border-red-500 border-2' : 'border-[#24386c]'}`}
                rows="16"
                readOnly={readOnly}
                placeholder={isFocused ? placeHolder : ''}
            ></textarea>
            <label
                htmlFor={inputId}
                className={`absolute top-1 left-1 transition-transform transition-duration-300 ${
                    isFocused || value ? 'transform -translate-y-6 -translate-x-1 scale-90 text-blue-500' : (isSubmitted && !value && enableMissingValueCheck ? 'text-red-400 text-xs font-semibold' : 'text-gray-500 text-xs')
                }`}
                style={{ transitionOrigin: 'left top' }}
            >
                {label}
            </label>
        </div>
    );
}

export default FloatingLabelTextArea;
