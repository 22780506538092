import React from 'react'

export default function GenericButton({mr, ml, enable, text, callback}) {
    return (
        <div>
            <button
                onClick={(event) => {
                    event.stopPropagation();
                    callback()
                }}
                className={`bg-[#24386c] ${ml} ${mr} ${!enable ? "" : "hover:bg-[#dc244c]"} px-1 rounded-sm border-[#24386c] border
    ${!enable ? "bg-[#e8ddfe] text-gray-800" : "bg-[#24386c] text-[#e8ddfe]"}`}
            >
                {text}
            </button>
        </div>
    )
}
