import React, { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../context/GlobalContextProvider';

export default function LoadingBar() {
  const { isLoading } = useContext(GlobalContext);
  const [animationKey, setAnimationKey] = useState(0); // To reset animation

  useEffect(() => {
    if (isLoading) {
      setAnimationKey(prevKey => prevKey + 1);
    }
  }, [isLoading]);

  return (
    <>
      {isLoading && (
        <div
          key={animationKey}
          className="h-[5px] bg-gradient-to-r from-[#dc244c] via-[#f47a42] to-[#dc244c] fixed left-0 top-0 w-full loadingBarAnimation"
        ></div>
      )}
    </>
  );
}