import React, { useContext, useMemo, useRef, useState } from 'react'
import FloatingLabelInput from '../../module/FloatingLabelInput';
import FloatingLabelSelect from '../../module/FloatingLabelSelect';
import { GlobalContext } from '../../context/GlobalContextProvider';
import { POP_UP, VIEW } from '../../util/Constants';
import { CandidateContext } from '../../context/CandidateContextProvider';
/* eslint-disable no-unused-vars */
import log from '../../util/Logger';
import { toast } from 'react-toastify';

const candidateIngestApiUrl = `https://hejha9p2m9.execute-api.ap-south-1.amazonaws.com/Prod/add-candidate`;
export default function CandidateForm() {
    const resumeFileRef = useRef(null);
    const [name, setName] = useState('');
    const [location, setLocation] = useState('');
    const [topSkills, setTopSkills] = useState('');
    const [experience, setExperience] = useState('');
    const [visa, setVisa] = useState('');
    const [workPref, setWorkPref] = useState('');
    const [openToRelocate, setOpenToRelocate] = useState('');
    const [resumeFile, setResumeFile] = useState(null);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { 
        addCandidatePopupRef,
        fetchCandidates,
        selectedCandidates,
        updateCandidate,
    } = useContext(CandidateContext);
    const {
        currentView,
        setPopup,
        setIsLoading,
        setLoadingBarDuration,
        uid,
        popup,
        candidateViewRefresher,
        candidateDetailsViewRefresher,
        setCandidateViewRefresher,
        setCandidateDetailsViewRefresher
    } = useContext(GlobalContext);

    const visaOptions = [
        { label: 'US Citizen', value: 'US Citizen' },
        { label: 'GC', value: 'GC' },
        { label: 'H1B', value: 'H1B' },
        { label: 'H1B Transfer', value: 'H1B Transfer' },
        { label: 'OPT', value: 'OPT' },
        { label: 'OPT STEM', value: 'OPT STEM' },
        { label: 'EAD', value: 'EAD' },
        { label: 'H1B EAD', value: 'H1 EAD' },
        { label: 'GC EAD', value: 'GC EAD' },
        { label: 'H4 EAD', value: 'H4 EAD' },
        { label: 'L2 EAD', value: 'L2 EAD' },
        { label: 'CPT', value: 'CPT' },
        { label: 'F1', value: 'F1' },
        { label: 'Other', value: 'Other' }     
    ];

    const workPrefOptions = [
        { label: 'Remote', value: 'Remote' },
        { label: 'Onsite', value: 'Onsite' },
        { label: 'Remote/Onsite Hybrid', value: 'Remote/Onsite Hybrid' },
        { label: 'Open', value: 'Open' },
    ];

    const openToRelocateOptions = [
        { label: 'Yes', value: 'Yes' },
        { label: 'No', value: 'No' }
    ];


    const handleResumeFileClick = () => {
        if (resumeFileRef.current) {
            resumeFileRef.current.value = null;
            setResumeFile(null);
        }
    };

    const handleResumeFileChange = (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const resumeFileName = e.target.files[0].name;
            if (!resumeFileName.endsWith('.pdf') && !resumeFileName.endsWith('.docx')) {
                toast.warn("File type not supported. Supported formats: pdf, docx");
            } else {
                setResumeFile(e.target.files[0]);
            }
        }
    };

    const updateCandidateLocal = async(updatedCandidate) => {
        await updateCandidate(updatedCandidate);
        setPopup(POP_UP.NONE);
        switch (currentView) {
            case VIEW.MY_CANDIDATES:
                setCandidateViewRefresher((candidateViewRefresher + 1) % 2);
                break;
            case VIEW.CANDIDATE_DETAILS:
                setCandidateDetailsViewRefresher((candidateDetailsViewRefresher + 1) % 2);
                break;
            default:
                break;
        }
    };

    const handleCandidateFormUpdate = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (name && location && topSkills && experience && visa && workPref && openToRelocate) {
            const updatedCandidate = {
                id: selectedCandidates[0].id,
                uid: selectedCandidates[0].uid,
                name: name,
                top_skills: topSkills,
                loc: location,
                exp: experience,
                visa: visa,
                work_pref: workPref,
                open_to_relocate: openToRelocate
            };
            if(resumeFile) {
                const reader = new FileReader();
                reader.readAsDataURL(resumeFile);
                reader.onload = async() => {
                    const base64Content = reader.result.split(',')[1];
                    updatedCandidate.resume_file_name = resumeFile.name;
                    updatedCandidate.resume_file_data = base64Content;
                    updateCandidateLocal(updatedCandidate);
                }
            } else {
                updateCandidateLocal(updatedCandidate);
            }
        } else {
            toast.error('Please fill all mandatory fields!');
        }
    }

    const handleCandidateFormSubmit = (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        const resumeFileName = resumeFile ? resumeFile.name : '';
        if (name && location && topSkills && experience && visa && workPref && openToRelocate && resumeFileName) {
            console.log('Form is valid');
            setLoadingBarDuration(10);
            setIsLoading(true);
            const reader = new FileReader();
            reader.readAsDataURL(resumeFile);
            reader.onload = () => {
                const base64Content = reader.result.split(',')[1];
                const payload = {
                    name: name,
                    top_skills: topSkills,
                    loc: location,
                    exp: experience,
                    visa: visa,
                    work_pref: workPref,
                    open_to_relocate: openToRelocate,
                    uid: uid,
                    resume_file_name: resumeFile.name,
                    resume_file_data: base64Content,
                };
                const post_params = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(payload),
                };
                fetch(candidateIngestApiUrl, post_params)
                    .then((response) => {
                        console.log('Response received:', response);
                        if (!response.ok) {
                            console.log('Response not ok, processing error...');
                            return response.text().then((errorText) => {
                                console.log("Error adding candidate", errorText);
                                throw new Error(errorText);
                            });
                        }
                        console.log(response.text)
                    })
                    .catch((error) => {
                        toast.error(error.message);
                    })
                    .finally(() => {
                        setIsLoading(false);
                        setLoadingBarDuration(5);
                        setPopup(POP_UP.NONE);
                        fetchCandidates(null, currentView);
                    });
            };
        } else {
            toast.error('Please fill all mandatory fields!');
        }
    }

    const defaultName = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].name;
        } else {
            return '';
        }
    }, [selectedCandidates]);

    const defaultLoc = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].loc;
        } else {
            return '';
        }
    }, [selectedCandidates]);

    const defaultTopSkills = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].top_skills;
        } else {
            return '';
        }
    }, [selectedCandidates]);

    const defaultExp = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].exp;
        } else {
            return '';
        }
    }, [selectedCandidates]);

    const defaultVisa = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].visa;
        } else {
            return 'US Citizen';
        }
    }, [selectedCandidates]);

    const defaultWorkPref = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].work_pref;
        } else {
            return 'Open';
        }
    }, [selectedCandidates]);

    const defaultOpenToRelocate = useMemo(() => {
        if (selectedCandidates.length === 1) {
            return selectedCandidates[0].open_to_relocate;
        } else {
            return 'No';
        }
    }, [selectedCandidates]);

    const isUpdateView = useMemo(() => {
        return popup === POP_UP.UPDATE_CANDIDATE_POPUP;
    }, [popup]);


    return (
        <div className="fixed inset-0 flex justify-center items-center bg-[#24386c] bg-opacity-20 font-roboto">
            <div ref={addCandidatePopupRef} className="absolute bg-white p-4 rounded-md border border-[#24386c] shadow-lg w-[700px] h-[450px]">
                <p className='text-base font-semibold'>Add candidate</p>
                <form onSubmit={isUpdateView ? handleCandidateFormUpdate : handleCandidateFormSubmit} 
                    className='text-gray-700 font-roboto text-xs'
                >
                    <div className="mt-5 flex flex-row justify-between">
                        <FloatingLabelInput label='Name' width='w-[48%]' inputId='name' placeHolder="Enter full name" value={defaultName} isSubmitted={isSubmitted} onChange={setName} />
                        <FloatingLabelInput label='Location' width='w-[48%]' inputId='loc' placeHolder="i.e. Houston, Texas, USA" value={defaultLoc} isSubmitted={isSubmitted} onChange={setLocation} />
                    </div>
                    <div className="mt-16 flex flex-row justify-between">
                        <FloatingLabelInput label='Top Skills' width='w-[48%]' inputId='topSkills' placeHolder="i.e. Full stack .Net/React Developer" value={defaultTopSkills} isSubmitted={isSubmitted} onChange={setTopSkills} />
                        <FloatingLabelInput label='Experience(years)' width='w-[48%]' type='number' inputId='exp' placeHolder="" value={defaultExp} isSubmitted={isSubmitted} onChange={setExperience} />
                    </div>
                    <div className="mt-16 flex flex-row justify-between">
                        <FloatingLabelSelect options={visaOptions} width="w-[30%]" label="Visa" inputId="visa" isSubmitted={isSubmitted} onChange={setVisa} defaultValue={defaultVisa} />
                        <FloatingLabelSelect options={workPrefOptions} width="w-[30%]" label="Work Preference" inputId="workPref" isSubmitted={isSubmitted} onChange={setWorkPref} defaultValue={defaultWorkPref} />
                        <FloatingLabelSelect options={openToRelocateOptions} width="w-[30%]" label="Open to Relocate" inputId="openToRelocate" isSubmitted={isSubmitted} onChange={setOpenToRelocate} defaultValue={defaultOpenToRelocate} />
                    </div>
                    <div className="mt-16 flex flex-row">
                        <FloatingLabelInput label='Resume File' width='w-[42%]' inputId='resume_file_name' placeHolder="" value={resumeFile === null ? "" : resumeFile.name} readOnly={true} isSubmitted={isSubmitted} enableMissingValueCheck={!isUpdateView} />
                        <input ref={resumeFileRef} type="file" id="resumeFileInput" className="hidden" onChange={handleResumeFileChange} onClick={handleResumeFileClick} />
                        <label htmlFor="resumeFileInput" className="bg-[#e8ddfe] border border-[#24386c] hover:bg-[#dc244c] px-2 py-1 ml-3 rounded-sm text-gray-800 text-[10px] font-semibold">
                            Browse Resume
                        </label>
                    </div>
                    <button
                        type="submit"
                        className="bg-[#24386c] hover:bg-[#dc244c] py-1 px-2 rounded-sm text-[#e8ddfe] mt-12 font-semibold">
                        {isUpdateView ? 'Update' : 'Submit'}
                    </button>
                </form>
            </div>
        </div>
    )
}
