import React, { useContext, useEffect, useState } from 'react'
/* eslint-disable no-unused-vars */
import log from "../../util/Logger";
/* eslint-enable no-unused-vars */
import { GlobalContext } from '../../context/GlobalContextProvider';
import { useNavigate } from 'react-router-dom';
import { VIEW } from '../../util/Constants';
import { toast } from 'react-toastify';

const loginUrl = "https://q1xp2yal7d.execute-api.ap-south-1.amazonaws.com/Prod/login"
const logoutUrl = "https://q1xp2yal7d.execute-api.ap-south-1.amazonaws.com/Prod/logout"
export default function Login(props) {
    const { setSid, setUid, setIsLoggedIn, setIsLoading, currentView, sid, isLoading } = useContext(GlobalContext);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const navigate = useNavigate();

    const handleEmailChange = (e) => setEmail(e.target.value);
    const handlePasswordChange = (e) => setPassword(e.target.value);

    const handleLogOut = async () => {
        setIsLoading(true);
        const payload = {
            sid: sid
        };
        const postParams = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        };
        try {
            const response = await fetch(logoutUrl, postParams);
            if (!response.ok) {
                const errorResponse = await response.json();
                throw new Error(errorResponse.message);
            } else {
                navigate(`/${VIEW.LOGIN}`);
            }
        } catch (error) {
            toast.error(error.message);
            navigate(`/${VIEW.ALL_JOBS}`);
        } finally {
            setSid(null);
            setUid(null);
            setIsLoggedIn(false);
            localStorage.removeItem('sid');
            setIsLoading(false);
        }
    }

    const handleLoginFormSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitted(true);
        if (email && password) {
            setIsLoading(true);
            const payload = {
                email: email,
                password: password
            };
            const postParams = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            };
            try {
                const response = await fetch(loginUrl, postParams);
                if (!response.ok) {
                    const errorResponse = await response.json();
                    throw new Error(errorResponse.message);
                } else {
                    const responseData = await response.json();
                    const sid = responseData.sid;
                    setSid(sid);
                    setIsLoggedIn(true);
                    setUid(email);
                    localStorage.setItem('sid', sid);
                    navigate(`/${VIEW.ALL_JOBS}`);
                }
            } catch (error) {
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const handleSignUpClick = () => {
        navigate(`/${VIEW.SIGNUP}`);
    };

    const handleForgotPassword = () => {
        navigate(`/${VIEW.FORGOT_PASSWORD}`);
    };

    useEffect(() => {
        if (currentView === VIEW.LOGOUT) {
            handleLogOut();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentView]);

    return (
        <div className="flex flex-col items-center justify-start h-full mt-32 font-roboto w-full">
            {(currentView === VIEW.LOGIN || (currentView === VIEW.LOGOUT && !isLoading)) && (
            <>
            <p className="text-2xl font-bold text-gray-800">Welcome back</p>
            <form className="flex flex-col items-center justify-center w-full mt-5" onSubmit={handleLoginFormSubmit}>
                <input type="text" placeholder="Email" id="email" onChange={handleEmailChange} value={email} className={`font-roboto w-1/5 p-3 border-[1px] ${isSubmitted && !email ? 'border-red-500' : 'border-gray-400'} focus:border-[#24386c] focus:border-[2px] rounded-md focus:outline-none`} />
                <input type="password" placeholder="Password" onChange={handlePasswordChange} value={password} className={`font-roboto w-1/5 p-3 mt-2 border-[1px] ${isSubmitted && !password ? 'border-red-500' : 'border-gray-400'} focus:border-[#24386c] focus:border-[2px] rounded-md focus:outline-none`} />
                <p className="mt-3 text-[13.5px] cursor-pointer text-blue-600 hover:text-blue-800 mr-44" onClick={handleForgotPassword}>
                    Forgot password?
                </p>
                <button type="submit" className="font-semibold px-16 py-2 mt-4 rounded-[3px] bg-[#24386c] hover:bg-[#dc244c] text-[#e8ddfe] border-[#24386c] border">Login</button>
            </form>
            <p className=" text-gray-800 mt-3">Don't have an account?{' '}
                <span className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={handleSignUpClick}>
                    Sign up
                </span>
            </p>
            </>
            )}
        </div>
    )
}
